import { render, staticRenderFns } from "./createPlan.vue?vue&type=template&id=082662a4&scoped=true&"
import script from "./createPlan.vue?vue&type=script&lang=js&"
export * from "./createPlan.vue?vue&type=script&lang=js&"
import style0 from "./createPlan.vue?vue&type=style&index=0&id=082662a4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "082662a4",
  null
  
)

export default component.exports